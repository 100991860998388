body {
    font-size: 10px;
    margin: 0;
    padding: 0;
    width: 100%;
}

h1 {
    font-size: 18px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 14px;
}

h4 {
    font-size: 12px;
}

[class*="col-xs-"], [class*="col-sm-"], [class*="col-md-"], [class*="col-lg-"] {
  float: left;
}

[class*="col-xs-pull-"], [class*="col-sm-pull-"], [class*="col-md-pull-"], [class*="col-lg-pull-"],
[class*="col-xs-push-"], [class*="col-sm-push-"], [class*="col-md-push-"], [class*="col-lg-push-"] {
  left: 0;
  right: 0;
}

#auction-details a[href]:after {
  content: "";
}

#wrapper {
  padding-left: 0px;
}